import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import {updateResource} from 'redux-json-api'

import MyDeputies from './MyDeputies'
import NoDeputies from './no_deputies/NoDeputies'
import Logger from '../../../../lib/NewLogger'

import routerUtils from '../../../../lib/routerUtils'
import {currentUserConfig} from '../../../../lib/userTools'
import {hasDeputyWithDeferredPermissions} from '../../../../lib/deputyPermissionTools'
import {scrollToTopOfPage} from '../../../../lib/scrollTools'
import {useEverplanData, useDeputyData} from '../../../../lib/hooks'
import {findRawResourceById} from '../../../../lib/plan_data/userData'
import MixpanelLogger from '../../../../lib/MixpanelLogger'

const MyDeputiesController = props => {
  const everplanId = props.userConfig.get('everplan-id')
  const {deputies} = useDeputyData(everplanId)
  const {itemResponses, newContacts} = useEverplanData(everplanId)

  const context = 'my_deputies'

  const logViewEvent = () => {
    const payload = {}
    const query_context = props.location.query.context

    if (query_context)
      payload.context = query_context

    MixpanelLogger.track('view_my_deputies', payload)
  }

  const goToAddDeputy = () => {
    MixpanelLogger.track('click_add_deputy', {context, deputy_count: deputies.size})

    Logger.log({
      name: 'clicked_add_new_deputy',
      payload: {context}
    })

    routerUtils.push('cm/deputies/new')
  }

  const exit = () => { routerUtils.push('cm/deputies') }

  const hasDeputyWithDeferredItems = hasDeputyWithDeferredPermissions(deputies)

  useEffect(() => {
    logViewEvent()
    scrollToTopOfPage()
  }, [])

  return (
    <div>
      {
        deputies.isEmpty() ?
          <NoDeputies
            currentUser={props.currentUser}
            updateResource={props.updateResource}
            goToAddDeputy={goToAddDeputy}
          /> :
          <MyDeputies
            {...props}
            deputies={deputies}
            hasDeputyWithDeferredItems={hasDeputyWithDeferredItems}
            goToAddDeputy={goToAddDeputy}
          />
      }
      {
        routerUtils.childrenWithProps(
          props.children,
          {
            ...props,
            context,
            exit,
            itemResponses,
            deputies,
            newContacts,
            hasDeputyWithDeferredItems
          }
        )
      }
    </div>
  )
}


MyDeputiesController.propTypes = {
  currentUser: PropTypes.instanceOf(Map),
  location: PropTypes.shape({
    query: PropTypes.object
  }),
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({categories, api}) => {
  const userConfig = currentUserConfig(api)

  return ({
    categories,
    currentUser: findRawResourceById({rawResource: api.users, id: userConfig.get('id')}),
    userConfig
  })
}

export default connect(mapStateToProps, {updateResource})(MyDeputiesController)
